import { useEffect, useState } from 'react'
import Meta from '@/components/Meta'
import isRunningInWebView from '@/lib/utils'
import { Button, Image, Link } from '@heroui/react'
import { signIn } from 'next-auth/react'
import { useTranslation } from 'react-i18next'

const Login = () => {
  const { t } = useTranslation()

  const [isWebView, setIsWebView] = useState(false)

  useEffect(() => {
    setIsWebView(isRunningInWebView())
  }, [])

  async function handleGoogleSignin() {
    await signIn('google', { callbackUrl: window.location.href })
  }

  async function handleDiscordSignin() {
    await signIn('discord', { callbackUrl: window.location.href })
  }

  async function handleFacebookSignin() {
    signIn('facebook', { callbackUrl: '/' })
  }

  return (
    <>
      <Meta title={` ${t('Login_Title')} | ${t('Gamerg-GG')} `} />

      <div className="flex-center xl:bg-[/images/login/background-image.webp)] h-[90vh] w-full bg-[url(/images/login/background-image.webp)] bg-cover md:bg-white xl:h-[95vh] md:dark:bg-jacarta-800">
        <div className="flex-center xl:shadow-large mx-auto h-fit gap-5 rounded-xl bg-white p-1 md:h-full md:w-full md:rounded-none md:p-2 xl:h-[75vh] xl:max-h-[50rem] xl:w-[70rem] xl:rounded-xl dark:bg-jacarta-800">
          <div className="relative hidden h-full max-h-[60rem] flex-1 self-start lg:flex lg:items-end">
            <Image
              src="/images/login/login-image.webp"
              classNames={{
                wrapper: 'w-full h-full absolute z-0',
                img: 'h-full w-full object-cover rounded-xl',
              }}
              className="w-full"
              alt="Login Background"
            />

            <div className="z-1 relative space-y-2 p-5 text-4xl font-semibold capitalize text-white">
              <p className="text-balance leading-none">
                {t('Join GamerG & win')}
              </p>
              <p className="leading-none">{t('everyday')}</p>
            </div>
          </div>

          <div className="flex-center md:-mt-32 md:w-[50%]">
            <div className="space-y-5 rounded-md px-10 py-10">
              <div className="mb-10 flex flex-col items-center justify-center">
                <Image
                  src="/images/logo_white.png"
                  alt="Gamerg.gg logo"
                  className="hidden max-h-28 md:max-h-36 dark:block"
                />
                <Image
                  src="/images/logo.png"
                  alt="Gamerg.gg logo"
                  className="block max-h-28 md:max-h-36 dark:hidden"
                />

                <h2 className="text-2xl font-semibold sm:text-4xl">
                  {t('Ready to win')}
                </h2>
              </div>

              {isWebView && (
                <div
                  className="bg-red border-red relative mb-3 rounded border px-4 py-3 text-justify text-white"
                  role="alert"
                >
                  {t('Login_Error')}

                  <span className="absolute bottom-0 right-0 top-0 px-4 py-3"></span>
                </div>
              )}

              <div className="flex w-full flex-col gap-2">
                <Button
                  size="lg"
                  radius="md"
                  variant="faded"
                  className="font-semibold"
                  onPress={handleDiscordSignin}
                  startContent={
                    <Image
                      src="/images/social/discord.svg"
                      classNames={{
                        img: 'rounded-none w-6 h-6',
                      }}
                      alt="Discord"
                    />
                  }
                >
                  <span className="text-sm md:text-base">
                    {t('Sign in with Discord')}
                  </span>
                </Button>

                <Button
                  size="lg"
                  radius="md"
                  variant="faded"
                  className="font-semibold"
                  onPress={handleGoogleSignin}
                  disabled={isWebView}
                  startContent={
                    <Image
                      src="/images/social/google.svg"
                      classNames={{
                        img: 'rounded-none w-6 h-6',
                      }}
                      alt="Google"
                    />
                  }
                >
                  <span className="text-sm md:text-base">
                    {t('Sign in with Google')}
                  </span>
                </Button>

                <Button
                  size="lg"
                  radius="md"
                  variant="faded"
                  className="font-semibold"
                  onPress={handleFacebookSignin}
                  disabled={isWebView}
                  startContent={
                    <Image
                      src="/images/social/facebook.svg"
                      classNames={{
                        img: 'rounded-none w-6 h-6',
                      }}
                      alt="Facebook"
                    />
                  }
                >
                  <span className="text-sm md:text-base">
                    {t('Sign in with Facebook')}
                  </span>
                </Button>
              </div>

              <div className="text-center">
                <p>{t('By logging in, you agree to GamerG.gg')}</p>
                <Link className="cursor-pointer" href="/privacy-policy">
                  {t('Privacy_Policy')}
                </Link>

                <span className="px-1">{t('and')}</span>
                <Link className="cursor-pointer" href="/terms-and-conditions">
                  {t('Terms_and_Conditions')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
